<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 18"
  >
    <path
      fill="currentColor"
      d="M14.45 4.8V1.5h-8.9v3.3h-1.5V0h11.9v4.8h-1.5Zm2.025 3.875A.72.72 0 0 0 17 8.45a.72.72 0 0 0 .225-.525A.72.72 0 0 0 17 7.4a.72.72 0 0 0-.525-.225.72.72 0 0 0-.525.225.72.72 0 0 0-.225.525c0 .2.075.375.225.525a.72.72 0 0 0 .525.225ZM14.45 16.5v-4.8h-8.9v4.8h8.9Zm1.5 1.5H4.05v-4.4H0V4.8h20v8.8h-4.05V18Zm2.55-5.9V6.3h-17v5.8h2.55v-1.9h11.9v1.9h2.55Z"
    />
  </svg>
</template>
